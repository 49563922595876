import React, { FC } from 'react';
import classNames from 'classnames';

import { Element } from 'layout';
import Image from 'common/Image';
import Typography from 'common/Typography';
import Button from 'components/Button';

import { BUTTON_TEXT_LENGTH } from './constants';

import { CARD_VARIANTS, CardProps } from './models.d';

import './Card.scss';

const { REGULAR, PROMO } = CARD_VARIANTS;

const Card: FC<CardProps> = ({
  className,
  variant = REGULAR,
  color,
  productTitle,
  productSubtitle,
  button: {
    url: { url },
    text,
    ariaLabel,
  },
  productImage,
  shortDescription,
}) => {
  const cardClasses = classNames('card', className, {
    [`card--${variant}`]: variant,
  });

  const titleClasses = classNames('card__title', className, {
    [`color--${color}`]: color,
  });

  const descriptionClasses = classNames('card__description', className, {
    [`color--${color}`]: color,
  });

  const shortDescriptionClasses = classNames('card__asterisk', className, {
    [`color--${color}`]: color,
  });

  const isPromoVariant = variant === PROMO;
  const imageProps = isPromoVariant ? 'cover' : 'contain';
  const isLongButtonText = text.length > BUTTON_TEXT_LENGTH;

  const renderShortDescription = shortDescription ? (
    <Typography
      as="p"
      size={12}
      align="center"
      className={shortDescriptionClasses}
      padding={{
        top: 'sm',
        left: 'sm',
        right: 'sm',
      }}
    >
      {shortDescription}
    </Typography>
  ) : null;

  return (
    <Element element="div" className={cardClasses} data-testid="card">
      <Element className="card__image" element="div">
        <Image objectFit={imageProps} {...productImage} className="card__packshot" />
      </Element>
      <Element className="card__container" element="div">
        <Typography as="p" size={24} weight="bold" align="center" className={titleClasses}>
          {productTitle}
        </Typography>
        <Typography
          size={isPromoVariant ? { base: 14, xl: 16 } : 16}
          align="center"
          className={descriptionClasses}
          dangerouslySetInnerHTML={productSubtitle}
        />
        <Button
          ariaLabel={ariaLabel}
          variant="solid"
          color={isPromoVariant ? 'white' : 'dark-green'}
          size={{ base: 20, lg: 28 }}
          padding={{
            top: { base: 'xs' },
            left: isLongButtonText ? { base: 'lg', md: 'xl' } : { base: 'lg', md: 'xxxl' },
            bottom: { base: 'xs' },
            right: isLongButtonText ? { base: 'lg', md: 'xl' } : { base: 'lg', md: 'xxxl' },
          }}
          weight="bold"
          className="card__button"
          url={url}
        >
          {text}
        </Button>
      </Element>
      {renderShortDescription}
    </Element>
  );
};

export default Card;
