import React, { FC } from 'react';
import classNames from 'classnames';

import { Container, Element } from 'layout';
import Image from 'common/Image';
import Typography from 'common/Typography';

import { TestimonialsProps, TestimonialsVariant } from './models.d';

import './Testimonials.scss';

const { PRIMARY } = TestimonialsVariant;

const Testimonials: FC<TestimonialsProps> = ({ variant = PRIMARY, title, icon }) => {
  const testimonialsClasses = classNames('testimonials', {
    [`testimonials--${variant}`]: variant,
  });

  const renderTitle: JSX.Element | null = title ? (
    <Typography
      className="testimonials__title text-transform--uppercase"
      size={{ base: 30, md: 50 }}
      align="center"
      padding={{ bottom: { base: 'xs', md: 'md' } }}
      as="h2"
    >
      {title}
    </Typography>
  ) : null;

  const renderTestimonials: JSX.Element[] = icon?.map(({ image, description }) => (
    <Element element="div" className="testimonials__container" key={image.alt}>
      <Image {...image} objectFit="contain" className="testimonials__image" />
      <Typography
        className="testimonials__description"
        align="center"
        padding={{ top: { base: 'sm', md: 'md' } }}
        size={18}
        dangerouslySetInnerHTML={description}
      />
    </Element>
  ));

  return (
    <Container className={testimonialsClasses} data-testid="testimonials">
      {renderTitle}
      <Element element="div" className="testimonials__wrapper">
        {renderTestimonials}
      </Element>
    </Container>
  );
};

export default Testimonials;
