type ImageNodes = 'mobileImage' | 'desktopImage' | 'image' | 'sticker';

export enum BannerBackgroundColor {
  MINT = 'mint',
  PURPLE = 'purple',
  GREEN = 'green',
}
export enum BannerContentColor {
  WHITE = 'white',
  DARK_GREEN = 'dark-green',
}

export enum BannerVariant {
  HERO = 'hero',
  PRODUCT = 'product',
  PRODUCTS_LIST = 'products-list',
  PROMO_PRIMARY = 'promo-primary',
  PROMO_SECONDARY = 'promo-secondary',
  SIMPLE_PRIMARY = 'simple-primary',
  SIMPLE_SECONDARY = 'simple-secondary',
  SIMPLE_TERTIARY = 'simple-tertiary',
  SIMPLE_QUATERNARY = 'simple-quaternary',
}

export interface BannerProps extends Partial<Record<ImageNodes, CommonTypes.ImageType>> {
  variant: BannerVariant;
  title?: string;
  description?: string;
  button?: CommonTypes.ButtonType;
  backgroundColor?: BannerBackgroundColor;
  contentColor?: BannerContentColor;
  className?: string;
  priceSpiderSku?: string;
}
