import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner, { BannerBackgroundColor, BannerVariant } from 'components/Banner';
import Testimonials, { TestimonialsVariant } from 'components/Testimonials';
import PromoProducts from 'containers/PromoProducts';

const { HERO } = BannerVariant;
const { GREEN } = BannerBackgroundColor;
const { PRIMARY } = TestimonialsVariant;

const HomePage: FC<HomepageTypes.HomepageProps> = ({
  data: {
    homepage: { urls, seo, heroBanner, twoColumnsPromo, iconSection },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner variant={HERO} backgroundColor={GREEN} {...heroBanner} />
      <Testimonials variant={PRIMARY} {...iconSection} />
      <PromoProducts twoColumnsPromo={twoColumnsPromo} />
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    homepage(lang: { eq: $lang }) {
      ...HomepageFragment
    }
  }
`;

export default HomePage;
