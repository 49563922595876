import React, { FC } from 'react';

import { Container, Element } from 'layout';
import Image from 'common/Image';
import Card, { CARD_COLOR_VARIANTS, CARD_VARIANTS } from 'components/Card';

import { PromoProductsProps } from './models';

import './PromoProducts.scss';

const PromoProducts: FC<PromoProductsProps> = ({ twoColumnsPromo }) => {
  const renderProducts: JSX.Element[] = twoColumnsPromo?.map((product, index) => {
    const {
      badge,
      title,
      description,
      button,
      backgroundImageMobile,
      backgroundImageDesktop,
      shortDescription,
    } = product;

    const renderProduct = (): JSX.Element | null => {
      if (!backgroundImageMobile && !backgroundImageDesktop) return null;

      let imageProps: CommonTypes.ImageType = { alt: '', imageData: {} };

      if (backgroundImageMobile && backgroundImageDesktop) {
        const { alt } = backgroundImageMobile;
        const { mobile } = backgroundImageMobile.imageData;
        const { desktop, tablet } = backgroundImageDesktop.imageData;

        imageProps = {
          alt,
          imageData: {
            mobile,
            tablet,
            desktop,
          },
        };
      }
      const cardColor = index === 1 ? CARD_COLOR_VARIANTS.WHITE : CARD_COLOR_VARIANTS.GREEN;

      return (
        <Card
          className="promo-products__products"
          variant={CARD_VARIANTS.PROMO}
          productTitle={title}
          productSubtitle={description}
          button={button}
          productImage={imageProps}
          shortDescription={shortDescription}
          color={cardColor}
        />
      );
    };
    const renderBadge = (): JSX.Element | null =>
      badge ? (
        <Element element="div" className="promo-products__badge">
          <Image {...badge} />
        </Element>
      ) : null;

    return (
      <Element className="promo-products__container" element="div" key={title}>
        {renderBadge()}
        {renderProduct()}
      </Element>
    );
  });

  return (
    <Container
      variant="wide"
      gutter={false}
      className="container--no-padding promo-products "
      data-testid="promo-products"
    >
      {renderProducts}
    </Container>
  );
};

export default PromoProducts;
