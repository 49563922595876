import { TypographyProps } from 'common/Typography';
import { ButtonProps } from 'components/Button';

import { BannerContentColor, BannerVariant } from './models.d';

const {
  HERO,
  PRODUCTS_LIST,
  PRODUCT,
  SIMPLE_PRIMARY,
  SIMPLE_SECONDARY,
  SIMPLE_TERTIARY,
  SIMPLE_QUATERNARY,
  PROMO_PRIMARY,
  PROMO_SECONDARY,
} = BannerVariant;

const { WHITE, DARK_GREEN } = BannerContentColor;

export const returnContentColor = (
  color: BannerContentColor
): 'color--white' | 'color--dark-green' => {
  switch (color) {
    case WHITE:
      return 'color--white';
    case DARK_GREEN:
      return 'color--dark-green';
    default:
      return 'color--white';
  }
};

export const returnTitleProps = (variant: BannerVariant, isLongTitle: boolean): TypographyProps => {
  switch (variant) {
    case HERO:
      return {
        as: 'h1',
        size: isLongTitle ? { base: 30, lg: 40, xxl: 65 } : { base: 40, lg: 65 },
      };
    case PRODUCTS_LIST:
      return {
        as: 'h1',
        size: { base: 40, lg: 50, xl: 65 },
      };
    case PRODUCT:
      return {
        as: 'h1',
        size: isLongTitle ? { base: 30, lg: 40, xxl: 65 } : { base: 30, lg: 65 },
      };
    case SIMPLE_PRIMARY:
    case SIMPLE_SECONDARY:
      return {
        as: 'h1',
        size: { base: 40, md: 55 },
        align: 'center',
      };
    case SIMPLE_TERTIARY:
      return {
        as: 'h2',
        size: { base: 35, md: 55 },
        align: 'center',
      };
    case SIMPLE_QUATERNARY:
      return {
        as: 'h1',
        size: { base: 40, md: 55 },
        align: { base: 'left', md: 'center' },
      };
    case PROMO_PRIMARY:
      return {
        as: 'p',
        size: 24,
        weight: 'bold',
        align: 'center',
      };
    case PROMO_SECONDARY:
      return {
        as: 'h2',
        size: isLongTitle ? { base: 20, md: 40, xxl: 55 } : { base: 24, md: 55 },
        align: { base: 'center', md: 'left' },
      };
    default:
      return {
        as: 'h1',
        size: { base: 40, md: 65 },
      };
  }
};

export const returnDescriptionProps = (
  variant: BannerVariant,
  isLongDescription: boolean
): TypographyProps => {
  switch (variant) {
    case SIMPLE_PRIMARY:
    case SIMPLE_SECONDARY:
      return {
        size: { base: 20, md: 24 },
        align: 'center',
      };
    case SIMPLE_QUATERNARY:
      return {
        size: { base: 20, md: 24 },
        align: { base: 'left', md: 'center' },
        weight: 'bold',
      };
    case SIMPLE_TERTIARY:
      return {
        size: 24,
        align: 'center',
      };
    case PROMO_PRIMARY:
      return {
        size: { base: 14, md: 16 },
        align: 'center',
      };
    case PROMO_SECONDARY:
      return {
        size: { base: 14, md: 24 },
        align: { base: 'center', md: 'left' },
        weight: 'bold',
      };
    default:
      return {
        size: isLongDescription ? { base: 18, md: 20, xxl: 24 } : { base: 20, md: 24 },
        weight: 'bold',
      };
  }
};

export const returnButtonProps = (
  variant: BannerVariant,
  color: BannerContentColor,
  isLongText: boolean
): ButtonProps => {
  switch (variant) {
    case PROMO_PRIMARY:
      return {
        variant: 'solid',
        padding: {
          top: { base: 'xs' },
          left: { base: 'lg', md: 'xxxl' },
          bottom: { base: 'xs' },
          right: { base: 'lg', md: 'xxxl' },
        },
        size: { base: 20, md: 28 },
        weight: 'bold',
        color: 'white',
      };
    case PROMO_SECONDARY:
      return {
        variant: 'solid',
        padding: {
          top: { base: 'xs' },
          left: isLongText ? { base: 'lg', md: 'xl' } : { base: 'lg', md: 'xxxl' },
          bottom: { base: 'xs' },
          right: isLongText ? { base: 'lg', md: 'xl' } : { base: 'lg', md: 'xxxl' },
        },
        size: { base: 20, md: 28 },
        weight: 'bold',
        color,
      };
    default:
      return {
        variant: 'solid',
        padding: {
          top: { base: 'xs' },
          left: isLongText ? { base: 'lg', md: 'xl' } : { base: 'xxxl', md: 'huge' },
          bottom: { base: 'xs' },
          right: isLongText ? { base: 'lg', md: 'xl' } : { base: 'xxxl', md: 'huge' },
        },
        size: isLongText ? { base: 20, md: 24, xxl: 28 } : 28,
        weight: 'bold',
        color,
      };
  }
};
