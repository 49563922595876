import React, { FC } from 'react';
import classNames from 'classnames';

import { Container } from 'layout';
import Image from 'common/Image';
import Typography from 'common/Typography';
import Button from 'components/Button';

import { BUTTON_TEXT_LENGTH, DESCRIPTION_TEXT_LENGTH, TITLE_TEXT_LENGTH } from './constants';
import {
  returnButtonProps,
  returnContentColor,
  returnDescriptionProps,
  returnTitleProps,
} from './utils';

import { BannerBackgroundColor, BannerContentColor, BannerProps, BannerVariant } from './models.d';

import './Banner.scss';

const { GREEN } = BannerBackgroundColor;
const { WHITE } = BannerContentColor;
const { PRODUCTS_LIST } = BannerVariant;

const Banner: FC<BannerProps> = ({
  mobileImage,
  desktopImage,
  image,
  sticker,
  title,
  description,
  button,
  variant,
  contentColor = WHITE,
  backgroundColor = GREEN,
  className,
  priceSpiderSku,
}) => {
  const bannerClasses = classNames('banner', className, {
    [`banner--${variant}`]: variant,
    [`banner--content-${contentColor}`]: contentColor,
    [`banner--background-${backgroundColor}`]: backgroundColor,
  });

  const renderBackground = (): JSX.Element | null => {
    if (!mobileImage && !desktopImage && !image) return null;

    let imageProps: CommonTypes.ImageType = { alt: '', imageData: {} };

    if (!image && mobileImage && desktopImage) {
      const { alt } = mobileImage;
      const { mobile } = mobileImage.imageData;
      const { desktop, tablet } = desktopImage.imageData;

      const tabletImage = variant === PRODUCTS_LIST ? mobile : tablet;

      imageProps = {
        alt,
        imageData: {
          mobile,
          tablet: tabletImage,
          desktop,
        },
      };
    }

    if (image) {
      imageProps = image;
    }

    return <Image {...imageProps} className="banner__background" />;
  };

  const renderTitle = (): JSX.Element | null => {
    if (!title) return null;

    const isLongTitle = title.length > TITLE_TEXT_LENGTH;
    const typographyProps = returnTitleProps(variant, isLongTitle);
    const titleClasses = classNames('banner__title', returnContentColor(contentColor));

    return (
      <Typography
        {...typographyProps}
        padding={{ bottom: { base: 'xs', md: 'sm' } }}
        className={titleClasses}
      >
        {title}
      </Typography>
    );
  };

  const renderDescription = (): JSX.Element | null => {
    if (!description) return null;

    const isLongDescription = description.length > DESCRIPTION_TEXT_LENGTH;
    const descriptionProps = returnDescriptionProps(variant, isLongDescription);
    const descriptionClasses = classNames('banner__description', returnContentColor(contentColor));

    return (
      <>
        <Typography
          {...descriptionProps}
          className={descriptionClasses}
          dangerouslySetInnerHTML={description}
        />
      </>
    );
  };

  const renderButton = (): JSX.Element | null => {
    if (priceSpiderSku) {
      return <div className="ps-widget" ps-sku={priceSpiderSku} />;
    }

    if (!button) return null;
    const { ariaLabel, text, url } = button;
    const isLongText = text.length > BUTTON_TEXT_LENGTH;

    const buttonProps = {
      ...returnButtonProps(variant, contentColor, isLongText),
      ariaLabel,
      url: url?.url,
    };

    return (
      <Button {...buttonProps} className="banner__button">
        {text}
      </Button>
    );
  };

  const renderSticker = (): JSX.Element | null => {
    if (!sticker) return null;

    return <Image objectFit="contain" {...sticker} className="banner__sticker" />;
  };

  return (
    <Container variant="wide" gutter={false} className={bannerClasses} data-testid="banner-item">
      {renderBackground()}
      <Container className="banner__content">
        {renderTitle()}
        {renderDescription()}
        {renderButton()}
      </Container>
      {renderSticker()}
    </Container>
  );
};

export default Banner;
