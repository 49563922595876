export enum CARD_COLOR_VARIANTS {
  WHITE = 'white',
  GREEN = 'dark-green',
}

export enum CARD_VARIANTS {
  REGULAR = 'regular',
  LISTING = 'listing',
  PROMO = 'promo',
}

export interface CardProps {
  productTitle: string;
  productSubtitle: string;
  button: CommonTypes.ButtonType;
  productImage: CommonTypes.ImageType;
  className?: string;
  variant?: CARD_VARIANTS;
  color?: CARD_COLOR_VARIANTS;
  shortDescription?: string;
}
